import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  nbNO,
  useGridApiRef 
} from '@mui/x-data-grid';
import axios from "axios";
import { DraggableMap } from './MapModal';



function EditToolbar(props) {
  const { setPlaceRows, setRowModesModel, selectedRowData } = props;
  const [latLng, setLatLng] = useState(null);

  const handleClick = () => {
    const id = uuid()
    setPlaceRows((oldRows) => [...oldRows, { id, fylke: '', kommune: '', gnr: '', bnr: '', isNew: true  }]);
    //console.log("PlaceRows updated:")
    //console.log(placeRows)
    //console.log(rowModesModel)
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'fylke' },
    }));

  };

  const setLocRow = async () => {
      const response = await axios.get(`https://api.kartverket.no/eiendom/v1/punkt?ost=${latLng.lng}&nord=${latLng.lat}&koordsys=4258&radius=1&filtrer=eiendom.bruksnummer%2Ceiendom.gardsnummer%2Ceiendom.kommunenummer%2Ceiendom.representasjonspunkt&treffPerSide=1&side=1`);
      console.log(response)
      const matrikkelData = response.data.eiendom[0];
      const kommuneData = await axios.get(`https://api.kartverket.no/kommuneinfo/v1/kommuner/${matrikkelData.kommunenummer}?filtrer=fylkesnavn%2Cfylkesnummer%2Ckommunenavn`)
      console.log(matrikkelData);
      console.log(kommuneData);
      if (matrikkelData) {
        setPlaceRows((oldRows) => [...oldRows, { 
                                                  id, 
                                                  fylke: {
                                                    "fylkesnavn": kommuneData.data.fylkesnavn, 
                                                    "fylkesnummer": kommuneData.data.fylkesnummer
                                                  }, 
                                                  kommune: {
                                                    "kommunenavn": kommuneData.data.kommunenavn, 
                                                    "kommunenummer": matrikkelData.kommunenummer
                                                  }, 
                                                  gnr: matrikkelData.gardsnummer, 
                                                  bnr: matrikkelData.bruksnummer, 
                                                  isNew: true  
                                                }
                                  ]);  
      };
      const id = uuid()
  };

  useEffect(() => {
    console.log(latLng)
    if (latLng) {
      setLocRow()
    }
  }, [latLng]);

  const updateFromAskeladden = () => {
    console.log(selectedRowData.ask_id)

    const fetchDataForId = async (id) => {
      try {
        const response = await axios.get(`https://api-proxy.humgis.uiocloud.no/api/v2/search/${id}`);
        return response.data.geometry.coordinates; // Assuming the API returns an object with a 'coordinates' array
      } catch (error) {
        console.error(`Error fetching data for id ${id}:`, error);
        return null;
      }
    };

    // A function to update the state with the new data
    const updateLatLngState = (coords) => {
      setLatLng(() => {
        if (coords) {
          console.log('Coords:', coords)
          return { lat: coords[1], lng: coords[0] }; // Flipping coordinates as per requirement
        }
      });
    };    
    // If ask_id is an array and not null, fetch data for each id
    if (Array.isArray(selectedRowData.ask_id)) {
      selectedRowData.ask_id.forEach(async (id) => {
        if (id) {
          const coords = await fetchDataForId(id);
          updateLatLngState(coords);
        }
      });
    }    
  }

  useEffect(() => {
    // A function to fetch data for a single id
    const fetchDataForId = async (id) => {
      try {
        const response = await axios.get(`https://api-proxy.humgis.uiocloud.no/api/v2/search/${id}`);
        return response.data.coordinates; // Assuming the API returns an object with a 'coordinates' array
      } catch (error) {
        console.error(`Error fetching data for id ${id}:`, error);
        return null;
      }
    };

    // A function to update the state with the new data
    const updateLatLngState = (coords) => {
      setLatLng((prevState) => {
        // Here we are appending each new set of coordinates to the state array
        if (coords) {
          return [
            ...prevState,
            { lat: coords[1], lng: coords[0] }, // Flipping coordinates as per requirement
          ];
        }
        return prevState;
      });
    };

    // If ask_id is an array and not null, fetch data for each id
    if (Array.isArray(selectedRowData.ask_id)) {
      selectedRowData.ask_id.forEach(async (id) => {
        if (id) {
          const coords = await fetchDataForId(id);
          updateLatLngState(coords);
        }
      });
    }
  }, [selectedRowData]);  


  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Ny
      </Button>
      <DraggableMap setLatLng={setLatLng} />
      <Button color="primary" startIcon={<AddIcon />} onClick={updateFromAskeladden} >
        Hent fra Askeladden
      </Button>     
    </GridToolbarContainer>
  );
}

// Custom edit component that uses Autocomplete for dropdown
function EditFylkeComponent({ id, value, field, apiRef, fylkeList, setFylkeList, setSelectedFylke }) {

  useEffect(() => {
    setSelectedFylke(value);

    const fetchOptions = async () => {
      try {
        const response = await axios.get('https://ws.geonorge.no/kommuneinfo/v1/fylker');
        setFylkeList(response.data);
      } catch (error) {
        console.error('Failed to fetch fylke options from Geo-Norge', error);
      }
    };
    fetchOptions();
  }, []);

  const handleChange = (_, newValue) => {
    setSelectedFylke(newValue);
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <Autocomplete
      value={value}
      sx={{ width: 200 }}
      onChange={handleChange}
      options={fylkeList}
      getOptionLabel={(option) => {
        // Check if option is an object, if not return the option itself (it's a string)
        if (typeof option === 'string') {
          return option;
        }
        return option ? `${option.fylkesnavn} (${option.fylkesnummer})` : '';
      }}
      isOptionEqualToValue={(option, value) => option.fylkesnavn === value}
      renderInput={(params) => (
        <TextField 
          {...params}
         />
      )}
      freeSolo // Allows for entering other names
    />
  );
}

function EditKommuneComponent({id, value, field, apiRef, selectedFylke}) {
  const [kommuneList, setKommuneList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedFylke?.fylkesnummer) {
      setLoading(true);
  
      axios.get(`https://ws.geonorge.no/kommuneinfo/v1/fylker/${selectedFylke.fylkesnummer}?filtrer=kommuner.kommunenavn%2Ckommuner.kommunenummer`)
        .then(response => {
            setKommuneList(response.data.kommuner);
            setLoading(false);
        })
        .catch(error=> {
            console.error("Error fetching kommuner: ", error);
            setLoading(false);
        })
    } else {
      setLoading(true);
  
      axios.get(`https://ws.geonorge.no/kommuneinfo/v1/kommuner?filtrer=kommunenavn%2Ckommunenummer&sorter=kommunenavn`)
        .then(response => {
            setKommuneList(response.data);
            setLoading(false);
        })
        .catch(error=> {
            console.error("Error fetching kommuner: ", error);
            setLoading(false);
        })
      
    }
  }, [selectedFylke]);

  const handleChange = (_, newValue) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <Autocomplete
      value={value}
      sx={{ width: 200 }}
      onChange={handleChange}
      options={kommuneList}
      loading={loading}
      getOptionLabel={(option) => option ? `${option.kommunenavn} (${option.kommunenummer})` : ''}
      isOptionEqualToValue={(option, value) => option.kommunenummer === value.kommunenummer}
      renderInput={(params) => (<TextField {...params} />)}
    />
  );
}

export default function Stedinfo({ selectedRowData, setSelectedRowData }) {
  console.log("Render Stedinfo")

  const [placeRows, setPlaceRows] = useState([]);
  const [placeChange, setPlaceChange] = useState(false);
  const [rowModesModel, setRowModesModel] = useState({});
  const [fylkeList, setFylkeList] = useState([]);  
  const [selectedFylke, setSelectedFylke] = useState({});

  const apiRef = useGridApiRef();

  useEffect(() => {
    if (selectedRowData) {
      const placesWithIds = (selectedRowData.sted_samlet || []).map((item, index) => ({ id: index, ...item }));
      //console.log('Setting placerows:', placesWithIds);
      setPlaceRows(placesWithIds);      
    }
  }, []);    

// Update selected row places when placerows change
  useEffect(() => {
    if (!placeChange) { //Don't run on render, only when user changes placeRows
      setPlaceChange(true)
    } else {
      console.log("Places updated")
      setSelectedRowData((prevState) => ({...prevState, sted_samlet: placeRows.map(({ isNew, ...rest }) => rest) }));
    }
  }, [placeRows]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setPlaceRows(placeRows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = placeRows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setPlaceRows(placeRows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setPlaceRows(placeRows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    //console.log(updatedRow)
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
        {
      field: 'fylke',
      headerName: 'Fylke',
      width: 150,
      editable: true,
      renderCell: 
        (params) => {
          if (typeof params.value === 'string') {
            // console.log('Fylke er av type string: ', params.value)
            return params.value;
          }
          // console.log('Fylke er av type objekt: ', params.value)
          return params.value?.fylkesnavn ?? '';
        },
      renderEditCell: (params) => {
        return <EditFylkeComponent 
          {...params} 
          apiRef={apiRef} 
          setSelectedFylke={setSelectedFylke}
          fylkeList={fylkeList}
          setFylkeList={setFylkeList}
        />;
      },
    },
    {
      field: 'kommune',
      headerName: 'Kommune', 
      width: 200,
      editable: true,
      renderCell:       
        (params) => {
          if (typeof params.value === 'string') {
            // console.log('Kommune er av type string: ', params.value)
            return params.value;
          }
          // onsole.log('Kommune er av type objekt: ', params.value)
          return params.value?.kommunenavn ?? '';
        },
      renderEditCell: (params) => (
        <EditKommuneComponent 
          {...params}
          apiRef={apiRef}
          selectedFylke={selectedFylke}
        />
      ),
    },
    { field: 'gnr', headerName: 'Gård', width: 80, editable: true },
    { field: 'bnr', headerName: 'Bruk', width: 80, editable: true },
    { field: 'actions',
      type: 'actions',
      headerName: 'Endre',
      width: 80,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Lagre"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Avbryt"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Rediger"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Slett"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ]; 

  return (
    <Box
      sx={{
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={placeRows}
        columns={columns}
        editMode="row"
        apiRef={apiRef}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setPlaceRows, setRowModesModel, selectedRowData },
        }}
        localeText={nbNO.components.MuiDataGrid.defaultProps.localeText}
      />
    </Box>
  );
}