import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar, gridFilteredSortedRowIdsSelector, useGridApiRef, nbNO } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';
//import { LoadingMessage } from './Loading';
import { useStore } from '../services/store';
import { Box } from '@mui/material';


const Table = ({ columns, apiTable, apiId, rowData, setSelectedRowUUID  }) => {
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const setFilteredSortedIds = useStore(state => state.setFilteredSortedIds);

  const { dataGridState, setDataGridState } = useStore((state) => ({
    dataGridState: state.dataGridState,
    setDataGridState: state.setDataGridState,
  }));

  let { id } = useParams(); 
  const navigate = useNavigate();

  const apiRef = useGridApiRef();
  

  const handleSortModelChange = (sortModel) => {
    setDataGridState({ sortModel });
  };

  const handleFilterModelChange = (filterModel) => {
    setDataGridState({ filterModel });
  };

  useEffect(() => {
    setFilteredSortedIds(gridFilteredSortedRowIdsSelector(apiRef.current.state))
  }, [dataGridState, apiRef, loading]);

  useEffect(() => {
    if (id && selectedRow != id) {
      console.log('Setting selected row to path id')
      setSelectedRow(id);
    }
  }, [id]);

  useEffect(() => {
    console.log('selectedRow')
    console.log(selectedRow)
    if (!selectedRow) {
      setSelectedRowUUID(null);
    } else {
      console.log("Row change detected")
      navigate(selectedRow)
      setSelectedRowUUID(selectedRow)
    }
  }, [selectedRow]);

  const handleRowSelected = (selectionModel) => {
    console.log('handleRowsel: ',selectionModel)
    setSelectedRow(selectionModel[0])
  }

  return (
    <Box sx={{ height: "calc(100vh - 200px)"}}>
    <DataGrid 
      rows={rowData}
      columns={columns}
      //pageSize={5}
      autoPageSize
      apiRef={apiRef}
      getRowId={(row) => row[apiId]}
      onRowSelectionModelChange={handleRowSelected}
      rowSelectionModel={[selectedRow]}
      sortModel={dataGridState.sortModel}
      onSortModelChange={handleSortModelChange}
      filterModel={dataGridState.filterModel}
      onFilterModelChange={handleFilterModelChange}
      loading={loading}
      sx={{
        '& .MuiDataGrid-columnHeaderTitle': {
          whiteSpace: 'normal',
          lineHeight: '1.2',
          maxHeight: '2.4em',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          wordBreak: 'break-word',
        },
      }}    
      slots={{
        toolbar: GridToolbar,
      }}
      slotProps={{ 
        toolbar: { 
          csvOptions: { 
            fileName:"KHM_prosjektering_" + apiTable,
            utf8WithBom: true
          },
          printOptions: {
            fileName:"KHM prosjektering: " + apiTable,
            hideFooter: true,
            hideToolbar: true,
            includeCheckboxes: true
          }
        } 
      }}
      localeText={nbNO.components.MuiDataGrid.defaultProps.localeText}
    />
    </Box>    
  );
}

export default Table;
