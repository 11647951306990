import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useStore } from '../services/store';


export default function MainTabs() {
  
  const { tab, setTab } = useStore((state) => ({
    tab: state.tab,
    setTab: state.setTab
  }));  

  const handleChange = (_, newValue) => {
    setTab(newValue);
  };

  return (
    <Tabs value={tab} onChange={handleChange} >
      <Tab label="Info" />
      <Tab label="Redigering"  />
      <Tab label="Tabell"  />
    </Tabs>
  )
}
