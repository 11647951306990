import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { nb, nn } from 'date-fns/locale';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 50,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  tableRow: {
    flexDirection: 'row',
    //borderBottomWidth: 1,
    //borderColor: '#000',
    //paddingBottom: 2,    
  },
  tableCell: {
    //borderWidth: 1,
    //borderColor: '#000',
    padding: 3,
  },
  colorCell: {
    //borderWidth: 1,
    backgroundColor: 'orange',
    padding: 5,
  },  
  borderCell: {
    borderBottomWidth: 0.5,
    borderColor: '#000',
    padding: 2,
  },  
  heading1: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'medium'
  },
  heading2: {
    fontFamily: 'Helvetica',
    fontWeight: 'medium',
    paddingBottom: 5 
  },  
  fylke: {
    fontFamily: 'Helvetica',
    fontSize: '11pt',
    color: 'red'
  },
  normal: {
    fontFamily: 'Times-Roman',
    fontSize: '11pt'
  },  
  bold: {
    fontFamily: 'Times-Bold',
    fontWeight: 'bold',
    fontSize: '11pt'
  },
  italic: {
    fontFamily: 'Times-Italic',
    fontStyle: 'italic',
    fontSize: '11pt'
  },
  footer: {
    position: 'absolute', // Position footer at an absolute position
    bottom: 50, // Specify the bottom offset
    left: 50,
    right: 50,
    paddingLeft: 10,
    paddingRight: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    borderTop: 0.5,
    borderTopColor: '#000',
    borderTopStyle: 'solid',
  },  
});

    const getFristerString = (data) => {
      if (!data) return '';

      return data
            .filter(({ frist }) => frist !== null) // Filter out items with null `frist`
            .map(({type, frist}) => {
        const formattedFrist = frist ? format(new Date(frist), 'yyyy-MM-dd') : 'null';
        return `${formattedFrist} (${type})`;
      }).join(', ');
    };  

// Create Document Component


const PostlistePdf = ({ data, date }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.heading1}>POST- OG KOMITEMØTE {format(date, 'EEEE', { locale: nb })} {format(date, 'PP', { locale: nb })} </Text>
      <Text style={styles.heading2}>SAKSKART</Text>
     

      {Object.entries(data)
        .sort(([saksbehandlerA], [saksbehandlerB]) => 
                    saksbehandlerA === 'Ufordelte' ? -1 
                  : saksbehandlerB === 'Ufordelte' ? 1 
                  : saksbehandlerA.localeCompare(saksbehandlerB)) 
        .map(([saksbehandler, items]) => (
        <>
          <View style={[styles.colorCell]}>
            <Text style={styles.bold} >{saksbehandler}</Text>
          </View>
        
          <View style={styles.tableContainer} >
            {items.map((item) => (
              <View wrap={false} key={item.uuid}>
                {/* Row -1 */}
                <View style={[styles.tableRow, styles.borderCell]} >
                </View>

                {/* Row 1 */}
                <View style={styles.tableRow} >
                  <View style={[styles.tableCell, {width: '15%'}]}>
                    <Text style={styles.fylke}>{item.fylke}</Text>
                  </View>
                  <View style={[styles.tableCell, {width: '30%'}]}>
                    <Text style={styles.italic} >{item.sted}</Text>
                  </View>
                  <View style={[styles.tableCell, {width: '30%'}]}>
                    <Text style={styles.italic} >{item.kommune}</Text>
                  </View>
                  <View style={[styles.tableCell, {width: '15%', textAlign: 'right'}]}>
                    <Text style={styles.normal} >{item.paragraf}</Text>
                  </View>
                  <View style={[styles.tableCell, {width: '10%'}]}>
                    <Text style={styles.normal} ></Text>
                  </View>
                </View>
        
                {/* Row 2 */}
                <View style={styles.tableRow}>
                  <View style={[styles.tableCell, {width: '15%'}]}>
                    <Text style={styles.normal} >{item.saksnummer}</Text>
                  </View>
                  <View style={[styles.tableCell, {width: '60%'}]}>
                    <Text style={styles.normal} >{item.saksnavn}</Text>
                  </View>
                  <View style={[styles.tableCell, {width: '15%', textAlign: 'right'}]}>
                    <Text style={styles.normal} >{item.paragraf_10 ? '+ §10' : null }</Text>
                  </View>
                  <View style={[styles.tableCell, {width: '10%'}]}>
                  </View>
                </View>
        
                {/* Row 3 */}
                <View style={styles.tableRow}>
                  <View style={[styles.tableCell, {width: '15%'}]}>
                  </View>
                  <View style={[styles.tableCell, {width: '12%'}]}>
                    <Text style={styles.bold} >Dato inn: </Text>
                  </View>
                  <View style={[styles.tableCell, {width: '13%'}]}>
                    <Text style={styles.normal} >{item.sak_inn ? format(new Date(item.sak_inn), 'dd.MM.yyyy', { locale: nb }) : null}</Text>
                  </View>                  
                  <View style={[styles.tableCell, {width: '12%'}]}>
                    <Text style={styles.bold} >Frister: </Text>
                  </View>
                  <View style={[styles.tableCell, {width: '38%'}]}>
                    <Text style={styles.normal} >{item.frist ? getFristerString(item.frist) : null}</Text>
                  </View>    
                  <View style={[styles.tableCell, {width: '5%'}]}>
                  </View>                     
                  <View style={[styles.tableCell, {width: '5%'}]}>
                    <Text style={styles.normal} ></Text>
                  </View>
                </View>
                  {item.paragraf_10 ?
                    <View style={styles.tableRow}>
                      <View style={[styles.tableCell, {width: '15%'}]}>
                      </View> 
                      <View style={[styles.tableCell, {width: '12%'}]}>
                        <Text style={styles.normal} >§10 >></Text>
                      </View>
                      <View style={[styles.tableCell, {width: '13%'}]}>
                        <Text style={styles.normal} >{item.p10_inn ? format(new Date(item.p10_inn), 'dd.MM.yyyy', { locale: nb }) : null}</Text>
                      </View>                  
                      <View style={[styles.tableCell, {width: '12%'}]}>
                      </View>
                      <View style={[styles.tableCell, {width: '13%'}]}>
                        <Text style={styles.normal} >{item.p10_ut ? format(new Date(item.p10_ut), 'dd.MM.yyyy', { locale: nb }) : null}</Text>
                      </View>

                      <View style={[styles.tableCell, {width: '35%'}]}>
                      </View> 
                    </View>
                  : null }

                {/* Row 4 */}
                <View style={styles.tableRow}>
                  <View style={[styles.tableCell, {width: '15%'}]}>
                  </View>
                  <View style={[styles.tableCell, {width: '85%'}]}>
                    <Text style={styles.bold} >Kommentar: </Text>
                    <Text style={styles.normal} >{item.kommentar}</Text>
                  </View>
                </View> 
              </View>
            ))}
          </View>
        </>
      ))}

      {/* Footer */}
      <View style={styles.footer} fixed>
        <Text
          style={styles.normal}
          render={() =>
            `Skrevet ut ${format(new Date(), 'EEEE', { locale: nb })}  ${format(new Date(),'PP',{ locale: nb }) }`
          }
        />
        <Text
          style={styles.normal}
          render={({ pageNumber, totalPages }) =>
            `Side ${pageNumber} av ${totalPages}`
          }
        />      
      </View>      
    </Page>
  </Document>
);

export default PostlistePdf;
