import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Autocomplete from '@mui/material/Autocomplete';
import LaunchIcon from '@mui/icons-material/Launch';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Table from '../components/Table';
import { LoadingMessage } from '../components/Loading';
import { makeRequest } from '../services/query';
import { useStore } from '../services/store';
  
const apiTable = 'v_engasjement'
const apiId = 'uuid'

function DateSelector({ selectedDate, setSelectedDate }) {

  const handleTodayClick = () => {
    const today = new Date();
    setSelectedDate(today);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };


  // To format date into a readable string 
  const formatDate = (date) => {
    return date ? date.toLocaleDateString() : '';
  };

  return (
    <Box display="flex" alignItems="center" gap={2} sx={{paddingBottom: 1}} >
      <Box component="span" fontSize="16px">Velg dato:</Box>
        <DatePicker
          value={selectedDate}
          onChange={handleDateChange}
          renderInput={(props) => <TextField {...props} variant="standard" />}
        />
      <Button 
        variant={selectedDate && formatDate(selectedDate) === formatDate(new Date()) ? 'contained' : 'outlined'}
        onClick={handleTodayClick} 
        size="large" 
      >
        I dag
      </Button>
    </Box>
  );
};

function ProsjektSelector({prosjekter, selectedProsjekt, setSelectedProsjekt}) {

  return (
    <Autocomplete
      style={{ flex: 1 }}
      value={selectedProsjekt || null} // Assuming selectedProsjekt is the state for the selected value
      onChange={(_, newValue) => {
        setSelectedProsjekt(newValue); // Assuming setSelectedProsjekt is the setter function for the state
        console.log(newValue);
      }}
      options={prosjekter} // The options prop expects an array like prosjekter
      getOptionLabel={(option) => option.label || ''}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={(params) => (
        <TextField 
          {...params}
          variant="outlined"
          label="Søk prosjektnavn"
        />
      )}
    />
  );
};




const HvemHvorHeader = () => {

  return (
    <Box sx={{ width: '100%', paddingBottom: 2 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="baseline">
          <Grid item xs={3} >
            <Box>
              <Typography variant="h6" component="span" sx={{ paddingRight: 2 }}>
                Hvem er Hvor?
              </Typography>
            </Box>
          </Grid> 
          <Grid item flexGrow={1}>
            <HvemTabs />
          </Grid>                        
        </Grid>
      </Box>
    </Box> 
  );
}

const HvemHvorPerson = ({ setSelectedRowUUID, selectedDate, setSelectedDate }) => {
  const [loading, setLoading] = useState(false);  
  const setSelectedAktoerUUID = useStore(state => state.setSelectedAktoerUUID);
  const setFilteredSortedIds = useStore(state => state.setFilteredSortedIds);
  const [rowData, setRowData] = useState([]);

  const navigate = useNavigate();

  const personColumns = [
    { field: 'ansatt', headerName: 'ansatt_id', width: 100 },
    { field: 'navn', headerName: 'Navn', width: 350 },
    { field: 'prosjektnavn', headerName: 'Prosjektnavn', width: 250 },
    { field: 'prosjekttype', headerName: 'Type', width: 150 },
    { field: 'ny_dato_start', headerName: 'Startdato', width: 100 },
    { field: 'ny_dato_slutt', headerName: 'Sluttdato', width: 100 },
    { field: 'varighet', headerName: 'Varighet', width: 200 },
  ];  

  const goToAktoer = (id) => {
    setSelectedAktoerUUID(id);
    navigate('/Aktør');
  };

  useEffect(() => {
    const getData = async () => {
      const apiDate = selectedDate.toISOString().split('T')[0];
      const data = await makeRequest('get', `${apiTable}?ny_dato_start=lte.${apiDate}&ny_dato_slutt=gte.${apiDate}`, {}, {});
      setRowData(data);
      const ids = data.map(row => row[apiId]);
      setFilteredSortedIds(ids);      
      setLoading(false);
    };   
    getData();
    console.log("apiTable has changed:", apiTable)
  }, [apiTable, selectedDate]);

  const PersonTable = () => (
      <Table
        columns={personColumns}
        apiTable={apiTable}
        apiId={apiId}
        setSelectedRowUUID={setSelectedRowUUID}
        rowData={rowData}        
      />
  );

  if(loading) {
    return (
      <LoadingMessage />
    ); 
  }
  
  return (
    <div>
      <Grid container spacing={2} >
        <Grid item sm={12} md={4} lg={4} xl={4}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Filter</Typography>
            <Grid container>
              <Grid >
                <DateSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
              </Grid>
              <Grid item sm={12}>
                <TextField name="navn" label="Personnavn" variant="outlined" fullWidth />
              </Grid>
            </Grid>
          </Paper>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Personinfo</Typography>
            <Grid container>
              <Grid >
              Navn: <br />
              Kontaktinfo: <br />
              <Button color="primary" startIcon={<LaunchIcon />} onClick={() => goToAktoer(setSelectedRowUUID)}>
                Åpne aktør
              </Button>
              </Grid>
            </Grid>
          </Paper>          
        </Grid>
        <Grid item sm={12} md={8} lg={8} xl={8}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Tabell</Typography>
            <PersonTable />
          </Paper>
        </Grid>        
      </Grid>
    </div>
  )
}

const HvemHvorProsjekt = ({ prosjekter, selectedDate, setSelectedDate, setSelectedRowUUID }) => {
  const [loading, setLoading] = useState(false);  
  const [selectedProsjekt, setSelectedProsjekt] = useState(null);
  const [apiString, setApiString] = useState(apiTable);
  const setSelectedProsjektUUID = useStore(state => state.setSelectedProsjektUUID);
  const setFilteredSortedIds = useStore(state => state.setFilteredSortedIds);
  const [rowData, setRowData] = useState([]);

  const navigate = useNavigate();
  

  const prosjektColumns = [
    { field: 'ansatt', headerName: 'Ansatt-ID', width: 100 },
    { field: 'navn', headerName: 'Navn', width: 350 },
    { field: 'telefon', headerName: 'Telefon', width: 150 },
    { field: 'epost', headerName: 'E-Post', width: 200 },
    { field: 'ny_dato_start', headerName: 'Startdato', width: 100 },
    { field: 'ny_dato_slutt', headerName: 'Sluttdato', width: 100 },
    { field: 'varighet', headerName: 'Varighet', width: 200 },
  ];  

  
  useEffect(() => {
    const params = new URLSearchParams();
    const apiDate = selectedDate ? selectedDate.toISOString().split('T')[0] : '';

    if (selectedDate) {
      params.append('ny_dato_start', `lte.${apiDate}`);
      params.append('ny_dato_slutt', `gte.${apiDate}`);
    }
    if (selectedProsjekt) {
      params.append('prosjekt_id', `eq.${selectedProsjekt.value}`);
    }
    setApiString(`${apiTable}?${params}`);
  }, [selectedDate, selectedProsjekt, apiTable]);

  useEffect(() => {
    const getData = async () => {
      const data = await makeRequest('get', apiString, {}, {});
      setRowData(data);
      const ids = data.map(row => row[apiId]);
      setFilteredSortedIds(ids);      
      setLoading(false);
    };   
    getData();
    console.log("apiTable has changed:", apiTable)
  }, [apiString]);

  const goToProsjekt = (id) => {
    setSelectedProsjektUUID(id);
    navigate('/Prosjekt');
  };

  const ProsjektTable = () => (
      <Table
        columns={prosjektColumns}
        apiTable={apiString}
        apiId={apiId}
        setSelectedRowUUID={setSelectedRowUUID}
        setLoading={setLoading}
        rowData={rowData}        
      />
  );

  if(loading) {
    return (
      <LoadingMessage />
    ); 
  }

  return (
    <div>
      <Grid container spacing={2} >
        <Grid item sm={12} md={4} lg={4} xl={4}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Filter</Typography>
            <Grid container>
              <Grid >
                <DateSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
              </Grid>
              <Grid sm={12}>
                <ProsjektSelector 
                  prosjekter={prosjekter}
                  selectedProsjekt={selectedProsjekt}
                  setSelectedProsjekt={setSelectedProsjekt}
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Prosjektinfo</Typography>
            <Grid container>
              <Grid >
              Navn: <br />
              Kontaktinfo: <br />
              { selectedProsjekt ?
              <Button color="primary" startIcon={<LaunchIcon />} onClick={() => goToProsjekt(selectedProsjekt.value)}>
                Åpne prosjekt
              </Button> : ''
              }
              <br />
              Kart
              </Grid>
            </Grid>
          </Paper>          
        </Grid>
        <Grid item sm={12} md={8} lg={8} xl={8}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Typography variant='h6'>Tabell</Typography>
            <ProsjektTable />
          </Paper>
        </Grid>            
      </Grid>
    </div>
  )
}

function HvemTabs() {
  
  const { tab, setTab } = useStore((state) => ({
    tab: state.hvemTab,
    setTab: state.setHvemTab
  }));  

  const handleChange = (_, newValue) => {
    setTab(newValue);
  };

  return (
    <Tabs value={tab} onChange={handleChange} >
      <Tab label="Person" />
      <Tab label="Prosjekt"  />
    </Tabs>
  )
}

const HvemHvor = () => {
  const tab = useStore(state => state.hvemTab);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedRowUUID, setSelectedRowUUID] = useState({});
  const [prosjekter, setProsjekter] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());

  console.log("HvemHvor rendered")
  console.log("Tab: ", tab)
  console.log("HvemUUID: ", selectedRowUUID)
  console.log("Dato: ", selectedDate)


  const handleRowSelected = async (uuid) => {
    const rowData = await makeRequest('get', `${apiTable}?${apiId}=eq.${uuid}`, {}, {});
    setSelectedRowData(rowData[0]);
  };


  useEffect(() => {
    console.log(selectedRowUUID)
    if(tab === 1 && selectedRowUUID){
        handleRowSelected(selectedRowUUID);
    }
  }, [tab]);

  const getListData = async () => {
    const prosjektList = await makeRequest('get', 'list_prosjekt', {}, {});
    setProsjekter(prosjektList);    
  };

  useEffect(() => {
    getListData();
  }, []);


  return (
    <div style={{ height: 'calc(100vh - 240px)', width: '100%' }}>
      <HvemHvorHeader />
      {tab === 0 && <HvemHvorPerson 
                      selectedDate={selectedDate} 
                      setSelectedDate={setSelectedDate} 
                      setSelectedRowUUID={setSelectedRowUUID} 
                    />}  
      {tab === 1 && <HvemHvorProsjekt 
                      prosjekter={prosjekter} 
                      selectedDate={selectedDate} 
                      setSelectedDate={setSelectedDate} 
                      setSelectedRowUUID={setSelectedRowUUID} 
                    />}      
      
    </div>
  );
}

export default HvemHvor;
