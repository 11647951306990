import { useEffect } from 'react';
import { makeRequest } from '../services/query';
import { useStore } from '../services/store';


export default function useCrudActions(
  { handleRowSelected, setSelectedUUID, selectedRowData, selectedUUID, apiTable, navigate }
) {
//States
  const setNewModalOpen = useStore(state => state.setNewModalOpen);
  const buttonAction = useStore(state => state.buttonAction);
  const currActor = useStore(state => state.currActor);
  const setButtonAction = useStore(state => state.setButtonAction);
  const setSnackbarOpen = useStore(state => state.setSnackbarOpen);
  const setSnackbarData = useStore(state => state.setSnackbarData);

// Functions for CRUD button actions
  const actions = {
    new: () => {
      setNewModalOpen(true);
      console.log('Ny');
      updateButtonAction("new", false);
    },
    save: async () => {
      console.log('Lagre');
      updateButtonAction("save", false);
      try {
        console.log(currActor)
        const updateData = {...selectedRowData, updated_by: currActor ? currActor.uuid : "web_client" };
        const updatedRow = await makeRequest(
                                  'PATCH', 
                                  `${apiTable}?uuid=eq.${selectedUUID}`,
                                  updateData, 
                                  "Prefer: return=representation"
                                );
        console.log('Data updated: ', updatedRow);
        handleRowSelected(selectedUUID);
        setSnackbarData ({
          "actionType": "save",
          "messageType": "success",
          "messageText": "Data lagra."
        });
      } catch (error) {
        console.log('Error saving data: ', error);     
        setSnackbarData ({
          "actionType": "save",
          "messageType": "error",
          "messageText": "Lagring feila: \n\n" + error.message + " \n " + error.response.data.message
        });
      }
     setSnackbarOpen(true)
    },
    revert: () => {
      console.log('Angre');
      updateButtonAction("revert", false);
      try {
        handleRowSelected(selectedUUID);
        setSnackbarData ({
          "actionType": "revert",
          "messageType": "info",
          "messageText": "Data tilbakestilt."
        });
      } catch (error) {
        console.log('Error reverting data. ');     
        setSnackbarData ({
          "actionType": "revert",
          "messageType": "error",
          "messageText": "Tilbakestilling feila."
        });
      };
      setSnackbarOpen(true)
    },
    delete: async () => {
      console.log('Slett ', selectedUUID);      
      updateButtonAction("delete", false);
      try {
        const deletedRow = await makeRequest('DELETE', `${apiTable}?uuid=eq.${selectedUUID}&limit=10&order=uuid`,'{}', "Prefer: return=representation");
        console.log("Rad sletta: ", deletedRow)
        setSelectedUUID(null);
        navigate('./');
        handleRowSelected(null);
        setSnackbarData ({
          "actionType": "delete",
          "messageType": "success",
          "messageText": deletedRow[0] ? "Rad " + deletedRow[0].uuid + " sletta." : "Fant ikke raden - den er allerede sletta."
        });
      } catch (error) {
        console.log('Error deleting data: ', error);     
        setSnackbarData ({
          "actionType": "delete",
          "messageType": "error",
          "messageText": "Sletting feila: \n\n" + error.message + " \n " + error.response.data.message
        });
      };
      setSnackbarOpen(true);
    },
  };

// Change actionButton state
  const updateButtonAction = (actionName, value) => {
    const updatedAction = { ...buttonAction, [actionName]: value };
    setButtonAction(updatedAction);
  };


// Running function on CRUD action button change
  useEffect(() => {
//    console.log('Current buttonAction:', buttonAction);

    const runAction = async (action) => {
      if (actions[action]) {
        await actions[action]();
      }
    };
  
    Object.keys(buttonAction).forEach((action) => {
      if (buttonAction[action]) {
        runAction(action);
      }
    });
  }, [buttonAction, selectedUUID ]);  

}  