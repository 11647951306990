import React from 'react';
import { Toolbar, Typography, IconButton } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useStore } from '../services/store';


const TopBar = () => {
  const userRole = useStore(state => state.userRole);
  const { drawerOpen, setDrawerOpen } = useStore((state) => ({
    drawerOpen: state.drawerOpen,
    setDrawerOpen: state.setDrawerOpen
  }));

  var rolleType = ''
  switch(userRole) {
    case 'pdb_readwrite':
      rolleType = 'Skrivetilgang'
      break;
    case 'pdb_readonly':
      rolleType = 'Lesetilgang'
      break;
    case 'pdb_admin':
      rolleType = 'Administrator'
      break;
    case 'pdb_digdok':
      rolleType = 'DigDok'
      break;         
    default:
      // code block
  };
  
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} >
      <Toolbar>
        {drawerOpen ? (
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        ) : (
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerOpen}>
            <MenuIcon />
          </IconButton>
        )}        
        <Typography variant="h6"  component="div" sx={{ flexGrow: 1 }}>
          KHM Prosjekteringsbase
        </Typography>
        <Typography variant="button"  component="div" >
          {rolleType}
        </Typography>        
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
