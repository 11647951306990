import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import LaunchIcon from '@mui/icons-material/Launch';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';

const KeyValueEditor = ({ name, jsonData, onSave, keyOptions = [], optionLabel = "label", keyLabel = "Type", valueLabel = "Adresse", addLabel = "Legg til", inputProps, link }) => {
  const [data, setData] = useState(jsonData ? Object.entries(jsonData) : []);

  const stringOptions = keyOptions.map(option => option[optionLabel]);

  useEffect(() => {
    setData(jsonData ? Object.entries(jsonData) : []);
  }, [jsonData]);

  const handleTypeChange = (index, value) => {
    const newData = [...data];
    newData[index][0] = value;
    setData(newData);
  };

  const handleAddressChange = (index, value) => {
    const newData = [...data];
    newData[index][1] = value;
    setData(newData);
  };

  const handleAddPair = () => {
    setData([...data, ['', '']]);
  };

  const handleRemovePair = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    const dataObject = Object.fromEntries(newData);
    
    console.log('Updated data after delete:', dataObject);
    const updatedData = {
      target: {
        name: name,
        value: dataObject        
      }
    };
    onSave(updatedData);
  };

  const handleSave = () => {
    const dataObject = Object.fromEntries(data);
    
    console.log('Updated dataafter entry:', dataObject);
    const updatedData = {
      target: {
        name: name,
        value: dataObject        
      }
    };
    onSave(updatedData);
  };

  return (
    <Grid container spacing={2}>
      {data.map(([jsonKey, jsonValue], index) => (
        <React.Fragment key={index}>
          <Grid xs={12} sm={4} md={4}>
            <Autocomplete
              freeSolo
              options={stringOptions}
              value={jsonKey}
              onChange={(event, newValue) => handleTypeChange(index, newValue)}
              onInputChange={(event, newValue) => handleTypeChange(index, newValue)}
              renderInput={(params) => 
                <TextField 
                  {...params}
                  label={keyLabel}
                  fullWidth
                  onBlur={handleSave}
                />
              }
            />
          </Grid>
          <Grid xs={10} sm={6} md={7}>
            <TextField 
              label={valueLabel}
              value={jsonValue}
              onChange={(e) => handleAddressChange(index, e.target.value)}
              onBlur={handleSave}
              fullWidth
              InputProps={{
                ...inputProps,
                endAdornment: link && jsonValue && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Gå til"
                      onClick={() => window.open(jsonValue, '_blank')}
                      edge="end"
                    >
                      <LaunchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}            />
          </Grid>
          <Grid xs={2} sm={2} md={1}>
            <IconButton onClick={() => handleRemovePair(index)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </React.Fragment>
      ))}
      <Grid xs={12}>
        <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddPair}>
          {addLabel}
        </Button>
      </Grid>
    </Grid>
  );
};

export default KeyValueEditor;
