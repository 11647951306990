import React from 'react';
import { Grid, Box, Typography, Chip } from '@mui/material';
import { useStore } from '../services/store';
import { useParams } from 'react-router-dom';
import MainTabs from './tabpanel';
import { CrudButtons, NavButtons } from './Buttons';


//const Search = ({selectedItem}) => {
//
//  return (
//    <Autocomplete
//      style={{ flex: 1 }}
//      multiple
//      value={selectedItem}
//      onChange={(_, newValues) => { }}                    
//      options={saksbehandlere}
//      getOptionLabel={(option) => option.value}
//      isOptionEqualToValue={(option, value) => option.id === value.id}
//      renderInput={(params) => (
//        <TextField 
//          {...params}
//          variant="outlined"
//          label="Finn"
//        />
//      )}
//    />
//  );
//}



const Status = () => {
  const status = useStore(state => state.status);  

  switch(status) {
    case 'pågår':
      return (
        <Chip label="Pågår" color="primary" />
      );
    case 'avslutta':
      return (
        <Chip label="Avslutta" color="success" />
      );
    case 'overtid':
      return (
        <Chip label="Overtid" color="error" />
      ); 
    default:
      return (
        <>
        </>
      );  
    };
}

const Header = ({selectedItem}) => {
  const tab = useStore(state => state.tab);
  let { id } = useParams();

  return (
    <Box sx={{ width: '100%', paddingBottom: 2 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="baseline">
          <Grid item xs={3} >
            <Box>
              <Typography variant="h6" component="span" sx={{ paddingRight: 2 }}>
                {selectedItem}
              </Typography>
                {tab !== 2 && (
                  <Status />
                )}
            </Box>
          </Grid>           
          <Grid flexGrow={1}>
            <MainTabs />
          </Grid>
          { id && (
            <Grid sx={{ paddingRight: 2 }}>
              <NavButtons />            
            </Grid>  
          )}
          <Grid sx={{ paddingRight: 2 }}>
          {tab === 1 && (
            <CrudButtons selectedItem={selectedItem} />
            )}
          </Grid>                
        </Grid>
      </Box>
    </Box> 
  );
}


export default Header;