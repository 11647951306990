import React, { useState, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { grey } from '@mui/material/colors';
import { AskeladdenDraggable } from './MapModal';


const ChipArray = ({ selectedRowData, setSelectedRowData, label }) => {

  const [chips, setChips] = useState([]);
  const [inputValue, setInputValue] = useState('');

// Update selected row ask_id when chips change
  useEffect(() => {
    setChips(selectedRowData?.ask_id || []);
  }, [selectedRowData]);

  const handleDelete = (chipToDelete) => {
    setChips((chips) => {
      const newChips = chips.filter((chip) => chip !== chipToDelete);
      setSelectedRowData((prevState) => ({...prevState, ask_id: newChips })); // Also update the selectedRowData state
      return newChips;
    });    
  };

  const handleAddChip = (event) => {
    if (event.key === 'Enter' && !isNaN(inputValue) && inputValue.trim() !== '') {
      const newChip = parseInt(inputValue.trim(), 10);
      // Prevent adding duplicate chips
      if (!chips.includes(newChip)) {
        setChips((prevChips) => {
          const newChips = [ ...prevChips, newChip ];
          setSelectedRowData((prevState) => ({...prevState, ask_id: newChips })); // Also update the selectedRowData state
          return newChips;
        });
      }
      setInputValue('');
    }
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center" sx={{ flexWrap: 'wrap' }}>
      {chips.map((chip, index) => (
        <Chip
          key={index}
          label={<AskeladdenDraggable id={chip} colour={grey[200]} />}
          onDelete={() => handleDelete(chip)}
        />
      ))}
      <TextField
        label={label}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleAddChip}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        sx={{ minWidth: '120px', flexShrink: 0, flexGrow: 1 }} // Set a minimum width, prevent shrinking and allow growing
      />
    </Stack>
  );
};

export default ChipArray;
