import { useCallback } from 'react';
import { useStore } from './store';
import { makeRequest } from './query';


export const useFetchActor = () => {

  //const setPopData = useStore(state => state.setPopData);
  //const setProsjekt = useStore(state => state.setProsjekt);
  const setCurrActor = useStore(state => state.setCurrActor);

  const fetchData = useCallback(async (email) => {
    try {
      const [
        actorData
      ] = await Promise.all([
        makeRequest('get', `rpc/match_actor_email?email=${email}`, {}, {}),
//        makeRequest('get', 'list_prosjekt?select=value,label', {}, {}),
      ]);
        setCurrActor(actorData[0]);
//      setProsjekt(prosjektData);
//      setPopData(true);
    } catch (error) {
      console.error('An error occurred while fetching data: ', error.response ? error.response.data : error.message);
//      setPopData(false);
      // handle error, maybe set a state variable to show an error message.
    }
  }, [setCurrActor]);

  return fetchData
};  