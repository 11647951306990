import React, { useState, useEffect } from 'react';
import { ErrorBoundary } from "react-error-boundary";
import Typography from '@mui/material/Typography';

import Header from '../components/Header';
import { LoadingMessage } from '../components/Loading';
import Table from '../components/Table';
import { makeRequest } from '../services/query';
import { useStore } from '../services/store';
import { CreateRecordModal } from '../components/Modals';
import ProsjektEdit from '../components/ProsjektEdit';
import {renderBooleanCell} from '../components/rendering';


const ProsjektInfo = () => (
  <Typography paragraph>
    Info om prosjekt.
  </Typography>
);

const ProsjektIngen = () => (
  <Typography paragraph>
    Inget prosjekt er valgt. Opprett nytt, eller velg eksisterende prosjekt i tabellen.
  </Typography>
);

const Prosjekt = () => {
  const [loading, setLoading] = useState(true);
  const [rowData, setRowData] = useState([]);
  const tab = useStore(state => state.tab);

  const selectedProsjektUUID = useStore.getState().selectedProsjektUUID;
  const setSelectedProsjektUUID = useStore(state => state.setSelectedProsjektUUID);
  const setFilteredSortedIds = useStore(state => state.setFilteredSortedIds);

  console.log("Prosjekt rendered")
  console.log("Tab: ", tab)
  console.log("ProsjektUUID: ", selectedProsjektUUID)

  const apiTable = 'v_prosjekt'
  const apiListTable = 'list_prosjekt_tabell'
  const editTable = 'edit_prosjekt'
  const apiId = 'uuid'

  useEffect(() => {
    const getData = async () => {
      const data = await makeRequest('get', `${apiListTable}?order=updated_at.desc`, {}, {});
      setRowData(data);
      const ids = data.map(row => row[apiId]);
      setFilteredSortedIds(ids);      
      setLoading(false);
    };   
    getData();
    console.log("apiListTable has changed:", apiListTable)
  }, [apiListTable]);

  const newModalFields = [
      { name: 'prosjektnavn', label: 'Prosjektnavn' },
      { name: 'prosjektkode', label: 'Prosjektkode' }
    ];

  const columns = [
    { field: 'prosjektnavn', headerName: 'Prosjektnavn', width: 200 },
    { field: 'prosjektkode', headerName: 'Kode', width: 100 },
    { field: 'prosjektleder', headerName: 'Prosjektleder', width: 200 },
    { field: 'rapport_ferdig', headerName: 'Rapport ferdig', type: 'boolean', renderCell: renderBooleanCell, width: 100 },
    { field: 'regnskap_signert', headerName: 'Regnskap signert', type: 'boolean', renderCell: renderBooleanCell, width: 100 },
    { field: 'feltarbeid_avslutta', headerName: 'Avslutta i felt', width: 100 },
    { field: 'finansieringskilde', headerName: 'Finansieringskilde', width: 100 },
    { field: 'ferdigstilling_frist', headerName: 'RA-frist', width: 100 },
    { field: 'utgravningsleder', headerName: 'Utgravningsleder', width: 300 },
    { field: 'kommentar', headerName: 'Merknad', width: 150 },
  ];  

  const ProsjektTable = () => (
      <Table
        columns={columns}
        apiTable={apiListTable}
        apiId={apiId}
        selectedRowUUID={selectedProsjektUUID}        
        setSelectedRowUUID={setSelectedProsjektUUID}
        rowData={rowData}        
      />
  );

  function fallbackRender({ error, resetErrorBoundary }) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.

    //Add error Snack here
  }

  if(loading) {
    return (
      <LoadingMessage />
    ); 
  }

  return (
    <div style={{ height: 'calc(100vh - 240px)', width: '100%' }}>
      <Header selectedItem={"Prosjekt"} />
      <ErrorBoundary
        fallbackRender={fallbackRender}
        onReset={(details) => {
          // Reset the state of your app so the error doesn't happen again
        }}
      >
        {tab === 0 && (selectedProsjektUUID ? <ProsjektInfo /> : <ProsjektIngen />)}
        {tab === 1 && 
          <ProsjektEdit 
            apiTable={apiTable}
            editTable={editTable}          
            apiId={apiId}
          />
        }
        {tab === 2 && <ProsjektTable />}
      </ErrorBoundary>
      <CreateRecordModal 
        fields={newModalFields} 
        apiTable={editTable}
      />
    </div>
  );
}

export default Prosjekt;
