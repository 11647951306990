import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function EditFristModal({ setSelectedRowData, index, frister, open, setOpen}) {
  const [values, setValues] = useState(frister[index]);
  const [selectedDate, setSelectedDate] = useState("");
  console.log('Frister index')
  console.log(frister[index])

    useEffect(() => {
    console.log("Values:")
    console.log(values)
  }, [values]);

//  const handleEdit = (index) => {
//    const newFrist = window.prompt('Enter new frist:', frister[index].frist);
//    const newStatus = window.prompt('Enter new status:', frister[index].status);
//
//    if (newFrist && newStatus !== null) {
//      const updatedFrister = frister.map((item, idx) =>
//        idx === index ? { ...item, frist: newFrist, status: newStatus } : item
//      );
//      setFrister(updatedFrister);
//    }
//  };

  const handleChange = (field) => (event) => {
    setValues({ ...values, field: event.target.value });
    console.log(values)
  };

  const handleDateChange = () => (event) => {
    setSelectedDate(event.target.value);
    setValues({ ...values, "frist": event.target.value + "T00:00:00" });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const updatedFrister = frister.map((item, idx) =>
      idx === index ? { values } : item
    );    
    setSelectedRowData(prevState => {
      return {...prevState, frist: updatedFrister};
    });
    console.log("record values: ", values)
    // Empty input fields
    setValues({});
    setSelectedDate("");
    // Close the modal after submitting
    setOpen(false); 
  };
  const handleCancel = () => {
    // Empty input fields
    setValues({});
    setSelectedDate("");
    // Close the modal after submitting
    setOpen(false); 
  };  

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
    >
      <Box sx={style}>
        <Typography variant='h6'>Endre frist</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label='Type'
            value={values?.type || ''}
            onChange={handleChange('type')}
            margin="normal"
            fullWidth
            required
          />
          <TextField
            label="Dato"
            name="frist"
            margin="normal"
            type="date"
            value={values?.frist || ''} 
            onChange={handleDateChange()}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth 
            required
          /> 
          <TextField
            label='Status'
            value={values?.status || ''}
            onChange={handleChange('status')}
            margin="normal"
            fullWidth
            required
          />                  
          <Button type="submit" variant="contained" color="primary">Lagre</Button>
          <Button type="cancel" onClick={() => handleCancel()} variant="contained" color="primary">Avbryt</Button>          
        </form>
      </Box>
    </Modal>
  );
}

export const FristElement = ({ frister, setSelectedRowData }) => {
  const [openEditFristModal, setOpenEditFristModal] = useState(false);  
  const [index, setIndex] = useState(null);  
  console.log("Kjører Frister")
  console.log(frister)


  const handleDelete = (index) => {
    if (window.confirm('Er du sikker på at du vil slette denne fristen?')) {
      const updatedFrister = frister.filter((_, idx) => idx !== index);
      setSelectedRowData(prevState => {
        return {...prevState, frist: updatedFrister};
      });
    }
  };

  const handleEdit = (index) => {
    setIndex(index);
    setOpenEditFristModal(true);
  };

  if (frister.length === 0) {
    // Render a message if there are no items in the array
    return <div>Ingen frister satt</div>;
  };

  return (
    <div>
      {frister.map((item, index) => (
        <Paper 
          key={index} 
          elevation={1} 
          sx={{ 
            padding: '8px', 
            marginBottom: '8px',
            position: 'relative',
          }}
        >
          <Box 
            sx={{ 
              position: 'absolute',
              top: 8, 
              right: 8, 
              display: 'flex', 
              flexDirection: 'row',
            }}
          >
            <IconButton 
              aria-label="Endre"
              onClick={() => handleEdit(index)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="Slett"
              onClick={() => handleDelete(index)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>        
          <em> {item.type.charAt(0).toUpperCase() + item.type.slice(1)} </em> <br />
          Frist: {item.frist || 'Ikke satt'} <br />
          Status: {item.status?.charAt(0).toUpperCase() + item.status?.slice(1) || 'Ukjent'}         
        </Paper>
      ))}
      <EditFristModal
        setSelectedRowData={setSelectedRowData}
        index={index}
        frister={frister}
        open={openEditFristModal}
        setOpen={setOpenEditFristModal}
      />      
    </div>
  );
};

export function FristModal({ setSelectedRowData, open, setOpen}) {
  const [values, setValues] = useState({});
  const [selectedDate, setSelectedDate] = useState("");

  const handleTypeChange = () => (event) => {
    setValues({ ...values, "type": event.target.value });
  };

  const handleDateChange = () => (event) => {
    setSelectedDate(event.target.value);
    setValues({ ...values, "frist": event.target.value + "T00:00:00" });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSelectedRowData(prevState => {
      let newFrist = [...(prevState.frist || []), values];
      return {...prevState, frist: newFrist};
    });
    console.log("record values: ", values)
    setValues({});
    setSelectedDate("");
    setOpen(false); // Close the modal after submitting
  };

//  console.log('Modal open status:', newModalOpen);
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
    >
      <Box sx={style}>
        <Typography variant='h6'>Legg til frist</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label='Type'
            value={values.type || ''}
            onChange={handleTypeChange()}
            margin="normal"
            fullWidth
            required
          />
          <TextField
            label="Dato"
            name="frist"
            margin="normal"
            type="date"
            value={selectedDate || ""} 
            onChange={handleDateChange()}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth 
            required
          />          
          <Button type="submit" variant="contained" color="primary">Opprett</Button>
          <Button type="cancel" onClick={() => setOpen(false)} variant="contained" color="primary">Avbryt</Button>          
        </form>
      </Box>
    </Modal>
  );
}