import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const PersonalTable = ({ data }) => {
  // Calculates the totals for count and varighet_timer
  const totals = Object.values(data).reduce(
    (acc, curr) => {
      acc.totalCount += curr.count;
      acc.totalVarighetTimer += curr.totalVarighetTimer;
      return acc;
    },
    { totalCount: 0, totalVarighetTimer: 0 }
  );

  const totalRowStyle = {
    fontWeight: 'bold',
    fontStyle: 'italic',
  };
  
  const lastRowCellStyle = {
    borderBottom: '2px solid gray',
  };

  const dataEntries = Object.entries(data)
  
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="aggregated table">
        <TableHead>
          <TableRow>
            <TableCell>Stillingsnavn</TableCell>
            <TableCell align="right">Antall</TableCell>
            <TableCell align="right">Timer totalt</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataEntries.map(([stillingId, { count, totalVarighetTimer, stillingsnavn }], index) => (
            <TableRow key={stillingId} style={index === dataEntries.length - 1 ? lastRowCellStyle : {}}>
              <TableCell>{stillingsnavn}</TableCell>
              <TableCell align="right">{count}</TableCell>
              <TableCell align="right">{totalVarighetTimer.toFixed(2)}</TableCell>
            </TableRow>
          ))}
          {/* Totals row */}
          <TableRow style={totalRowStyle}>
            <TableCell >I alt</TableCell>
            <TableCell align="right">{totals.totalCount}</TableCell>
            <TableCell align="right">{totals.totalVarighetTimer.toFixed(2)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PersonalTable;
