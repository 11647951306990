import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';

export const LoadingMessage = () => {

  const [loadingMessage, setLoadingMessage] = useState("Laster inn …");

    const apiAvailabile = async () => {
      try {
        const response = await axios.get('https://pdb.humgis.uiocloud.no/v_sak');
        if (response.status === 200) {
          console.log('API is available');
          return true;
        }
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          console.error(error.response.status); // If it's an Axios error and it has a response status, set that status
          return false;
        } else {
          return false;
        }
      }
    };


  useEffect(() => {

    const timerId = setTimeout(() => {
      setLoadingMessage("Innlasting tar lengre tid enn vanlig …");
    }, 3500);  
  
    const apiTimerId = setTimeout(() => {
      if (!apiAvailabile) {
        setLoadingMessage("Mangler kontakt med databasen.");
      }
    }, 5000);

    return () => {
      clearInterval(timerId);
      clearInterval(apiTimerId);
    }
  }, []);  

  return (
    <Grid 
      container 
      spacing={5} 
      direction="column"
      alignItems="center" 
    >
      <Grid>
      </Grid>      
      <Grid>
        <CircularProgress />
      </Grid>
      <Grid>
        <Typography variant="body1">{loadingMessage} </Typography>
      </Grid>
    </Grid>
  );
}