import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';

import Header from '../components/Header';
import { LoadingMessage } from '../components/Loading';
import Table from '../components/Table';
import { makeRequest } from '../services/query';
import { useStore } from '../services/store';
import { CreateRecordModal } from '../components/Modals';
import DroneEdit from '../components/DroneEdit';

const DroneInfo = () => (
  <Typography paragraph>
    Info om flygninger.
  </Typography>
);

const DroneIngen = () => (
  <Typography paragraph>
    Ingen flygning er valgt. Opprett ny, eller velg eksisterende flygning i tabellen.
  </Typography>
);

const Drone = () => {
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const tab = useStore(state => state.tab);
  const selectedFlygningUUID = useStore.getState().selectedFlygningUUID;
  const setSelectedFlygningUUID = useStore(state => state.setSelectedFlygningUUID);
  const setFilteredSortedIds = useStore(state => state.setFilteredSortedIds);

  console.log("Flygning rendered")
  console.log("Tab: ", tab)
  console.log("FlygningUUID: ", selectedFlygningUUID)

  const apiTable = 'edit_flight'
  const editTable = 'edit_flight'
  const apiListTable = 'list_flight_tabell'
  const apiId = 'uuid'

  useEffect(() => {
    const getData = async () => {
      const data = await makeRequest('get', apiListTable, {}, {});
      setRowData(data);
      const ids = data.map(row => row[apiId]);
      setFilteredSortedIds(ids);      
      setLoading(false);
    };   
    getData();
    console.log("apiTable has changed:", apiListTable)
  }, [apiListTable]);  

  const newModalFields = [
      { name: 'lokalitetens_navn', label: 'Lokalitetens navn' }
    ];

  const columns = [
    { field: 'lokalitetens_navn', headerName: 'Lokalitet', width: 200 },
    { field: 'dato', headerName: 'Dato', width: 75 },
    { field: 'uav', headerName: 'Drone', width: 75 },    
    { field: 'sensor', headerName: 'Sensor', width: 75 },
    { field: 'fartoysjef', headerName: 'Fartøysjef', width: 150 },
    { field: 'stedlig_ansvarlig', headerName: 'Stedlig ansvarlig', width: 150 },
    { field: 'agl', headerName: 'Høyde AGL', width: 150 },
    { field: 'flytid', headerName: 'Flytid', width: 150 },
  ];  

  const DroneTable = () => (
      <Table
        columns={columns}
        apiTable={apiTable}
        apiId={apiId}
        setSelectedRowUUID={setSelectedFlygningUUID}
        rowData={rowData}        
      />
  );

//  if(loading) {
//    return (
//      <LoadingMessage />
//    ); 
//  }

  return (
    <div style={{ height: 'calc(100vh - 240px)', width: '100%' }}>
      <Header selectedItem={"Flygning"} />
      {tab === 0 && (selectedFlygningUUID ? <DroneInfo /> : <DroneIngen />)}
      {tab === 1 && 
        <DroneEdit 
          apiTable={apiTable}
          editTable={editTable}          
          apiId={apiId}
        />
      }      
      {tab === 2 && <DroneTable />}
      <CreateRecordModal 
        fields={newModalFields} 
        apiTable={editTable}
      />
    </div>
  );
}

export default Drone;
