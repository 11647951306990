const NoPage = () => {
  return (
    <>
      <h1>404</h1>
      <p>Denne sida kommer seinere.</p>
    </>
  );
};

export default NoPage;
